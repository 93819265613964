@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');

:root {
  --primary: #87c530;
  --secondary: #2C5F2D;
  --black: #111827;
  --lightGray: #dddddd;
  --lightPrimary: #87c53044;
}

::selection {
  background: var(--lightPrimary);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: var(--lightPrimary);
  /* Gecko Browsers */
}

a {
  color: var(--primary);
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: var(--secondary);
}

.graphic {
  position: fixed !important;
  z-index: 0;
  bottom: 0;
  right: 0;
}

.loading {
  height: 25rem;
  width: 100%;
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
  border-radius: 0.5rem;
}

@keyframes loading {
  from {
    background-color: var(--lightGray);
  }
  to {
    background-color: whitesmoke;
  }
}

.content a {
  font-weight: bold;
}

.content img {
  width: 100%;
}

.content sup {
  line-height: 1;
}

.App {
  margin-top: 4rem;
  text-align: flex-start;
  display: flex;
  flex: 1;
  color: var(--black);
  pointer-events: box-none;
}

.container {
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
  max-width: 52rem;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  z-index: 1;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 200px;
  flex-shrink: 0;
}

.cart{
  top: 4rem;
  position: sticky;
}

.header {
  height: 7rem;
}

h1 {
  margin: 0;
  font-family: "Cabin", sans-serif;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
}

h1 a {
  color: var(--black);
  text-decoration: none;
}

h2 {
  font-family: "Cabin", sans-serif;
  font-weight: normal;
  font-size: 30px;
  margin-top: 1.3em;
}

h3 {
  margin-bottom: -.5em;
}

.logo {
  height: 7rem;
}

.logo img {
  height: 2.7rem;
  margin-top: 3px;
  margin-left: .5rem;
  background-color: var(--secondary);
  border-radius: 9999px;
  aspect-ratio: 1;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.katex-display math {
  width: 100%;
  overflow: scroll;
}

.content *:first-child {
  margin-top: 0rem;
}

.content .pageTitle {
  padding-top: 2.5rem;
  border-top: 1px var(--lightGray) solid;
  margin-block-end: 2.5rem;
}

.content .pageTitle:last-of-type {
  margin-block-end: 1rem;
}


.content .pageTitle:first-of-type {
  padding-top: 0;
  border-top: 0 var(--lightGray) none;
}


.content p:first-of-type {
  margin-top: 0rem;
}

.content p:first-child {
  margin-top: -.375rem !important;
}

.content pre {
  position: relative;
}

.content pre:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 0.5rem;
  z-index: -1;
  background: linear-gradient(141deg, rgba(17, 24, 39, 1) 0%, rgba(44, 95, 45, 1) 85%, rgba(17, 24, 39, 1) 300%) !important;
  opacity: 1;
}

.content .codeBox {
  font-size: .875rem;
  line-height: 1rem;
  background-color: var(--black) !important;
  transition: box-shadow 0.7s, padding-left 0.2s, background-color 0.7s;
  color: white;
  border-radius: .5rem !important;
  padding: 0rem;
}

.content .codeBox:hover {
  box-shadow: inset 3px 3px 20px 1px var(--black);
  padding-left: 1.1rem !important;
  background-color: transparent !important;
}

.content code {
    font-size: .875rem;
    line-height: 1rem;
    background-color: var(--lightGray);
}

.content .codeBox code {
  background: transparent !important;
}

.content .katex-html {
  display: none;
}

.katex-display {
    display: flex;
    width: 100%;
    justify-content: center;
}

.katex-display > .katex {
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: scroll;
  text-align: center;
}

.footnotes {
  border-top: 3px dotted var(--lightGray);
  font-size: .875rem;
  line-height:1.25rem;
  margin-top: 2.5rem;
  padding-top: 1rem
}

.footnotes #footnote-label {
  display:none
}

.navbar {
  font-family: ui-sans-serif, system-ui, Arial, sans-serif;
  position: sticky;
  font-size: .875rem;
  line-height: 1.25rem;
}

.navbar .navlink:first-child {
  margin-top: -.375rem;
}

.navlink {
  display: flex;
  flex-direction: row;
}

.navlink a {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: .25rem;
  padding-bottom: .25rem;
  transition: color 0.2s;
  color: gray;
}

a .activeDot {
  pointer-events: none;
  cursor: not-allowed;
  background-color: transparent;
  width: 6px;
  height: 6px;
  border-radius: 9999px;
  margin-right: .5rem;
}

.active .activeDot {
  background-color: var(--primary);
  transition: background-color 0.2s;
}

.active {
  color: var(--primary) !important;
}

.navlink a:hover {
  color: var(--secondary);
}

.navbar a {
  text-decoration: none;
}

.blocker {
  top: 0;
  width: 6px;
  height: 100%;
  padding-right: .5rem;
  position: absolute;
}

.content {
  width: 100%;
  font-size: 1rem;
  line-height: 1.75rem;
  font-family: 'PT Serif', 'Georgia', ui-serif, 'Cambria', 'Times New Roman', 'Times', "Hiragino Kaku Gothic Pro", Osaka, Meiryo, "MS PGothic", serif;
}

.footer {
  margin-top: 3.5rem;
  display: flex;
  font-size: .875rem;
  justify-content: space-between;
  align-items: center;

}

.copyright {
  color: gray;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.links svg {
  height: 18px;
  width: 18px;
  margin-left: .5rem;
  fill: gray;
  transition: fill 0.2s;
}

.information {
  height: 20px !important;
  width: 20px !important;
}

.information:hover {
  fill: var(--secondary);
}

.github:hover {
  fill: #181616;
}

.rss:hover {
  fill: #F87900;
}

.linkedin:hover{
  fill: #0076b2;
}

.blogPost .blogEntry {
  pointer-events: none;
}

.blogEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: color 0.2s;
  line-height: 1;
}

.blogTitle {
  color: var(--black);
  transition: color 0.2s;
}

.blogTime {
  color: gray;
  white-space: nowrap;
  padding-left: 1rem;
  line-height: 1.25;
}

.metadata {
  margin-top: -.8rem;
}

.content .blogTitle:first-child {
  margin-top: 0;
}

.content .blogEntry:first-child {
  border-top: none;
}

.blogEntry:hover .blogTitle {
  color: var(--secondary);
}

i {
  font-size: 2rem;
}

.devicons, .devicons-black {
  display: flex;
  width: 85%;
  flex-wrap: wrap;
  row-gap:.5rem;
}
.devicons i, .devicons-black i {
  margin-right: 0.5rem;
}

.devicons-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.devicons-black {
  position: absolute;
  z-index: 2;
  top: 0;
}

.devicons-black i {
  color: var(--black) !important;
  transition: opacity 0.2s;
}

.devicons-black i:hover {
  opacity: 0;
}

.readMore {
  text-align: right;
}

.gh i {
  color: var(--black) !important;
  transition: color 0.2s;
}

.gh i:hover {
  color: var(--secondary) !important;
}

.portalImg {
  overflow: hidden;
  margin-bottom: .5rem;
}

.portalImg img {
  display: block;
  transition: transform 0.2s ease;
}

.portalImg img:hover {
  transform:scale(1.02);
}

.mobile-header {
  display: none;
  height: 4rem;
  font-size: 36px !important;
}

hr {
  color: transparent;
  border-top: 1px var(--lightGray) solid;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

@media (max-width: 768px) {
  .container{
    flex-direction: column;
    padding-left: 20px;
  }
  .header {
    display: none;
  }
  .mobile-header {
    display: flex;
  }
  .content {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .navbar .navlink:first-child {
    margin-top: 0rem;
  }
  .navbar .navlink {
    margin-right: 1rem;
  }
  .navlink a {
    font-size: 1rem;
    flex-direction: column-reverse;
  }
  .footer {
    font-size: 1rem;
  }
  .footnotes {
    font-size: 1rem;
    line-height:1.5rem;
  }
  a .activeDot {
    margin-right: 0;
  }
  .katex-display {
    font-size: 1rem;
  }

  .logo {
    height: 5rem;
  }

  .logo img {
    height: 2.7rem;
    margin-top: 3px;
    margin-left: 0;
    background-color: var(--secondary);
    border-radius: 9999px;
    aspect-ratio: 1;
  }
  .links svg {
    height: 32px;
    width: 32px;
  }
  .links svg.information {
    height: 34px !important;
    width: 34px !important;
  }
  .links svg.rss {
    height: 34px !important;
    width: 34px !important;
  }
  .App {
    margin-top: 2rem;
  }
  .blogTime {
    white-space: normal;
  }
}
